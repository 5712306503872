<section class="body-bg">

    <img class="wave" src="assets/images/wave-v3.png" alt="">
    <div class="w-100">
        <img src="assets/images/logo.png" class="img-fluid ml-5 mt-2 position-absolute d-none d-lg-block" alt="Logo"
            width="200">
    </div>

    <div class="container">
        <div class="img">
            <img src="assets/images/login-img.png" class="animated fadeInUp crm" style="border-radius: 10%;" alt="">
        </div>

        <div class="login-content">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="animated fadeIn login-form"
                *ngIf="loginBox">
                <img src="assets/images/logo.png" class="img-fluid d-lg-none d-sm-block" alt="Logo">

                <h2 class="title text-dark mt-0">Maven CRM</h2>

                <h3 class="title text-dark mt-0">LOGIN</h3>
                <p>To keep connected with us, please login with your Employee Id and Password
                    <span class=""></span>
                </p>

                <div class="input-div one">
                    <div class="i">
                        <i class="mdi mdi-account"></i>
                    </div>
                    <label class="has-float-label">
                        <span>Employee ID</span>
                        <input class="form-control" type="email" placeholder="Enter Employee Id. Ex.-EIMTI22IN01"
                            formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Employee ID is required</div>
                        </div>
                    </label>
                </div>
                <div class="input-div pass">
                    <div class="i">
                        <i class="mdi mdi-lock"></i>
                    </div>
                    <label class="has-float-label">
                        <span>Password</span>
                        <input class="form-control" type="password" placeholder="********" formControlName="password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </label>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <a class="text-dark font-weight-bold" href="javascript:void(0);" (click)="ForgotPassword();">Forgot
                        Password?</a>
                </div>
                <div class="d-flex align-items-center my-3">
                    <input type="submit" class="btn login-btn" value="Login">
                </div>
            </form>

            <form [formGroup]="forgetPassForm" class="animated fadeIn" *ngIf="forgotPasswordBox"
                (ngSubmit)="submitForgetPassForm()">
                <img src="assets/images/logo.png" class="img-fluid d-lg-none d-sm-block" alt="Logo">

                <h2 class="title text-dark mt-0">Maven CRM</h2>
                <h3 class="title text-dark mt-0">Forgot Password</h3>

                <p>Enter your account email to receive a link allowing you to reset your password or contact to your
                    administrator
                    <span class=""></span>
                </p>
                <div class="input-div one">
                    <div class="i">
                        <i class="mdi mdi-account"></i>
                    </div>
                    <label class="has-float-label">
                        <input class="form-control" formControlName="email_id" type="email"
                            placeholder="Enter email address. Ex.- xyz@maven-trading.com"
                            [ngClass]="{ 'is-invalid': submittedForm && p.email_id.errors }" />
                        <div *ngIf="submittedForm && p.email_id.errors" class="invalid-feedback">
                            <div *ngIf="p.email_id.errors.required">Email is required</div>
                            <div *ngIf="p.email_id.errors.email">Email must be a valid email address</div>
                        </div>
                        <span>Email</span>
                    </label>
                </div>
                <div class="d-flex align-items-center my-3">
                    <input type="submit" class="btn" value="Reset Password">
                    <input type="submit" class="btn bg-info ml-3" value="Go Back" (click)="login();">
                </div>
            </form>
        </div>
    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>