import { Component, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { Router } from '@angular/router';
import { UserService } from '../../services/userService/user.service'
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

declare let $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  userInformation: any = {}
  permission: any;
  modulename: any;
  modulepermission: any = [];
  Commercial: any;
  Company: any;
  CompanyProfile: any;
  Contact: any;
  Dashboard: any;
  Email: any;
  Finance: any;
  Inquiry: any;
  Logistics: any;
  OrderReview: any;
  Procurement: any;
  Product: any;
  Project: any;
  SalesTarget: any;
  Task: any;
  User: any;
  userid: any;
  Uname: any;
  profileImgUrl: string;
  userRId: any;
  Target: any;
  ReportDashboard:any;

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private router: Router,
    private UserService: UserService,
    private toastrService: ToastrService,
  ) {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }
  }
  // End open close
  ngOnInit() {

    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.getUserDetail();
    let permi = JSON.parse(localStorage.getItem('ModulePermission'));
    this.permission = this.UserService.decryptData(permi);
    let userrId = localStorage.getItem('roleId');
    this.userRId = this.UserService.decryptData(userrId);
    let data = this.permission;
    this.Commercial = data['Commercial']
    this.Company = data['Company']
    this.CompanyProfile = data['CompanyProfile']
    this.Contact = data['Contact']
    this.Dashboard = data['Dashboard']
    this.Email = data['Email']
    this.Finance = data['Finance']
    this.Inquiry = data['Inquiry']
    this.Logistics = data['Logistics']
    this.OrderReview = data['OrderReview']
    this.Procurement = data['Procurement']
    this.Product = data['Product']
    this.Project = data['Project']
    this.Task = data['Task']
    this.User = data['User']
    this.SalesTarget = data['SalesTarget']
    this.ReportDashboard = data['ReportDashboard']
  }
  getUserDetail() {
    let id = localStorage.getItem('userId');
    this.userid = this.UserService.decryptData(id);

    let data = {
      "UserId": this.userid
    }
    this.UserService.GetUserDetail(data).subscribe(res => {
      if (res['result'] == 'success') {
        this.userInformation = res['Data'];
      }
    })
    this.UserService.GetUserOwnDetail(data).subscribe(res => {
      if (res['result'] == 'success') {
        this.Uname = res['Data']['UserName'];
        if (res['Data']['ImagePath'] == '' || res['Data']['ImagePath'] == null || res['Data']['ImagePath'] == 'Uploads/User_Doc/') {
          this.profileImgUrl = environment.default_img;
        } else {
          this.profileImgUrl = environment.app_path + res['Data']['ImagePath'];
        }
      }
    })

  }
  logoutFunction() {
    let data = {
      "UserId": this.userid
    }
    this.UserService.UserLogout(data).subscribe(res => {
      if (res['result'] == 'success') {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("createdUserId");
        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        this.toastrService.error(res['Message'])
      }
    })
  }
}
