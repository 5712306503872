<div class="row justify-content-between align-items-center mb-3 zIndexUp">
  <h4 class="pl-3 mb-0"><a href="javascript:void(0);" routerLink="/profile">
      <span class="mdi mdi-arrow-left"></span></a> Change Password</h4>
</div>

<div class="form-row animated fadeIn">
  <div class="col-lg-4 col-md-5 col-sm-6">
    <div class="card">

      <div class="card-body">
        <form [formGroup]="changePasswordForm" #custForm="ngForm">
          <div class="form-row">
            <div class="form-group col-12 col-sm-12">
              <label for="" class="d-inline-block justify-content-between align-items-center">Old Password<span
                  class="required ml-1">*</span></label>
              <input required formControlName="oldPassword"
                [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" name="oldPassword" class="form-control"
                type="password" />
            </div>
            <div
              *ngIf="changePasswordForm.get('oldPassword').invalid && changePasswordForm.get('oldPassword').errors && (changePasswordForm.get('oldPassword').dirty || changePasswordForm.get('oldPassword').touched) || submitted">
              <small class="text-danger" *ngIf="(changePasswordForm.get('oldPassword').hasError('required'))">
                Old Password is Required.
              </small>
              <small class="text-danger" *ngIf="message!=''">
                {{message}}
              </small>
            </div>

            <div class="form-group col-12 col-sm-12">
              <label for="" class="d-inline-block justify-content-between align-items-center"> New Password<span
                  class="required ml-1">*</span></label>
              <input required formControlName="newPassword"
                [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" name="newPassword" class="form-control"
                type="password" maxlength="15" />
            </div>
            <div
              *ngIf="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').errors && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched) || submitted">
              <small class="text-danger" *ngIf="(changePasswordForm.get('newPassword').hasError('required'))">
                New Password is Required.
              </small>
              <small class="text-danger" *ngIf="(changePasswordForm.get('newPassword').hasError('minLength'))">
                Password must be at least 6 characters
              </small>
              <small class="text-danger" *ngIf="(changePasswordForm.get('newPassword').hasError('pattern'))">
                Password must be valid and Password must be at least 6 characters long
              </small>
            </div>

            <div class="form-group col-12 col-sm-12">
              <label for="" class="d-inline-block justify-content-between align-items-center">Confirm New Password<span
                  class="required ml-1">*</span></label>
              <input required formControlName="ConfirmPassword"
                [ngClass]="{ 'is-invalid': submitted && f.ConfirmPassword.errors }" name="ConfirmPassword"
                class="form-control" type="password" />
            </div>
            <div
              *ngIf="changePasswordForm.get('ConfirmPassword').invalid && changePasswordForm.get('ConfirmPassword').errors && (changePasswordForm.get('ConfirmPassword').dirty || changePasswordForm.get('ConfirmPassword').touched) || submitted">
              <small class="text-danger" *ngIf="(changePasswordForm.get('ConfirmPassword').hasError('required'))">
                Confirm Password is Required.
              </small>

              <small class="text-danger" *ngIf="this.passmatch !=true">
                new password and confirm password does not match.
              </small>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-12">
              <a type="submit" href="javascript:void(0);" class="btn btn-info"
                (click)="passwordMatch();ChangePassword();"
                [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }">Save</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>