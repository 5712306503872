import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../services/userService/user.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  userDetails: any;
  currentpasswordvalid: boolean = true;
  confirmpassvalid = true;
  passmatch: boolean = false;
  newpassvalid = true;
  submitted = false;
  UserId: any;
  userid: any;
  userInformation: any;
  message: any = "";

  constructor(
    private fb: FormBuilder,
    private UserService: UserService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let id = localStorage.getItem("User_ID");
    this.changePasswordForm = this.fb.group({
      token: [id, Validators.required],
      oldPassword: ["", Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,15}"
          ),
          Validators.minLength(6),
          Validators.maxLength(15),
        ],
      ],
      ConfirmPassword: ["", Validators.required],
    });
  }

  get r() {
    return this.changePasswordForm.controls;
  }

  currentPassword(event: any) {
    let pass = event.target.value;
    let reg = /^[0-9a-zA-Z!@#$%^&*]+$/;
    if (reg.test(pass)) {
      let decimal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{6,15}$/;
      if (pass.match(decimal)) {
        this.currentpasswordvalid = true;
        return true;
      } else {
        this.currentpasswordvalid = false;
        return false;
      }
    } else {
      event.target.value = event.target.value.trim();
    }
  }

  passwordMatch() {
    if (
      this.changePasswordForm.value.newPassword != "" &&
      this.changePasswordForm.value.ConfirmPassword != ""
    ) {
      if (
        this.changePasswordForm.value.newPassword ==
        this.changePasswordForm.value.ConfirmPassword
      )
        this.passmatch = true;
      return true;
    } else {
      this.passmatch = false;
      return false;
    }
  }

  ChangePassword() {
    this.submitted = true;
    if (
      !this.currentpasswordvalid ||
      !this.passmatch ||
      !this.confirmpassvalid ||
      !this.newpassvalid
    ) {
      return false;
    } else {
      const data = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.newPassword,
      };
      this.submitted = true;
      this.UserService.ChangePassword(data).subscribe(
        (res) => {
          if (res["result"] == "success") {
            this.message = "";
            this.toastrService.success(res["Msg"]);
            window.location.reload();
          }
          if (res["result"] == "Failed") {
            this.message = res["Msg"];
          }
        },
        (err) => {}
      );
    }
  }

  get f() {
    return this.changePasswordForm.controls;
  }
}
