<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <li [class.active]="showMenu === 'f1'" class="mt-3">
      <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" (click)="addExpandClass('f1')"
        aria-expanded="false">
        <img src="{{ profileImgUrl }}" alt="" class="img-fluid img-thumbnail" width="40" />

        <span class="hide-menu">{{ Uname }}</span>
      </a>
      <ul aria-expanded="false" class="collapse" [class.in]="showMenu === 'f1'">
        <li>
          <a href="javascript:void(0);" routerLink="/profile"><i class="mdi mdi-account"></i> My Profile</a>
        </li>

        <li>
          <a href="javascript:void(0);" (click)="logoutFunction()"><i class="mdi mdi-power"></i> Logout</a>
        </li>
      </ul>
    </li>

    <li class="mt-2" *ngIf="Company == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLink="/company/company-list" routerLinkActive="active">
        <span class="mdi mdi-store"></span>
        <span class="hide-menu">Company</span></a>
    </li>

    <li class="mt-2" *ngIf="Contact == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/contacts/contacts-list">
        <span class="mdi mdi-phone"></span>
        <span class="hide-menu">Contact</span></a>
    </li>

    <li class="mt-2" *ngIf="User == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/user/user-list">
        <span class="mdi mdi-account-outline"></span>
        <span class="hide-menu">User</span></a>
    </li>
    <li class="mt-2" *ngIf="Product == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/productmanagment/productlist">
        <span class="mdi mdi-package-variant-closed"></span>
        <span class="hide-menu">Product Management</span></a>
    </li>

    <li class="mt-2" *ngIf="Project == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/project/projectlist">
        <span class="mdi mdi-package-variant"></span>
        <span class="hide-menu">Project</span></a>
    </li>

    <li class="mt-2" *ngIf="Inquiry == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/inquiry/inquirylist">
        <span class="mdi mdi-gavel"></span>
        <span class="hide-menu">Inquiry</span></a>
    </li>

    <li class="mt-2" *ngIf="Task == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/Task/TaskList">
        <span class="mdi mdi-note-multiple-outline"></span>
        <span class="hide-menu">Task</span></a>
    </li>
    <li class="mt-2" *ngIf="OrderReview == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/orderReview/orderreview">
        <span class="mdi mdi mdi-message-reply-text"></span>
        <span class="hide-menu">Order Review</span></a>
    </li>

    <li class="mt-2" *ngIf="SalesTarget == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/salestarget/sales-target-list">
        <span class="mdi mdi-pin"></span>
        <span class="hide-menu">Sales Target</span></a>
    </li>

    <li class="mt-2">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/report">
        <span class="mdi mdi-file-outline"></span>
        <span class="hide-menu">Report</span></a>
    </li>

    <li class="mt-2" *ngIf="ReportDashboard == '1' || userRId == 1">
      <a href="javascript:void(0)" routerLinkActive="active" routerLink="/report-dashboard">
        <span class="mdi mdi-chart-bar"></span>
        <span class="hide-menu">Report Dashboard</span></a>
    </li>

  </ul>
</nav>