// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// const BASE_URL = "https://api.maven-technologies.com/";
// const base_files_url = "https://staging.maven-technologies.com/";

const BASE_URL = "https://api.maventradingcrm.com/";
const base_files_url = "https://maventradingcrm.com/";

// const BASE_URL = "http://43.229.227.26:81/MavenCRM/";
// const base_files_url = "http://43.229.227.26:81/Maven-CRM/";

// const BASE_URL = "http://localhost:1027/";
// const base_files_url = "http://43.229.227.26:81/Maven-CRM/";

export const environment = {
  production: false,
  api_url: BASE_URL + "api",
  app_profile_path: BASE_URL + "Uploads/User_Doc/",
  app_temp_path: BASE_URL + "Uploads/User_Doc/Thumbnail/",
  app_download_path: BASE_URL,
  app_downpath: BASE_URL + "Uploads/Company/",
  app_path: BASE_URL,
  user_profile_img: base_files_url + "assets/images/users/default-user.png",
  default_temp: base_files_url + "assets/images/users/images.png",
  tempimage: BASE_URL,
  showmore: base_files_url + "assets/images/Arrow_Down_Show_More.svg",

  //Ashutosh
  orderDownloadPath: BASE_URL + "Uploads/OrderOverview/",
  approveDownloadPath: BASE_URL + "Uploads/OrderOverview/pdf/",
  upload_image_url: base_files_url + "assets/images/",
  upload_file_icon: base_files_url + "assets/images/file_icons/",
  upload_file_format: base_files_url + "assets/images/format/",
  import_sample: BASE_URL + "uploads/Excel/",
  default_img: BASE_URL + "Content/Image/no-image.png",
  // profile_img : "https://api.maven-technologies.com/",

  upload_user_file: base_files_url + "assets/images/",
  upload_user_fileIcon: base_files_url + "assets/images/file_icons/",

  // api_url: "http://43.229.227.26:81/MavenCRM/api",
  // app_profile_path: "http://43.229.227.26:81/MavenCRM/Uploads/User_Doc/",
  // app_temp_path: "http://43.229.227.26:81/MavenCRM/Uploads/User_Doc/Thumbnail/",
  // app_download_path :"http://43.229.227.26:81/MavenCRM/",
  // app_downpath  :"http://43.229.227.26:81/MavenCRM/Uploads/Company/",
  // app_path: "http://43.229.227.26:81/MavenCRM/",
  // user_profile_img: "https://maventradingcrm.com/assets/images/users/default-user.png",
  // default_temp : "https://maventradingcrm.com/assets/images/users/images.png",
  // tempimage:"http://43.229.227.26:81/MavenCRM/",
  // showmore:"https://maventradingcrm.com/assets/images/Arrow_Down_Show_More.svg",

  // //Ashutosh
  // upload_image_url : "https://maventradingcrm.com/assets/images/",
  // upload_file_icon : "https://maventradingcrm.com/assets/images/file_icons/",
  // upload_file_format : "https://maventradingcrm.com/assets/images/format/",
  // import_sample : "http://43.229.227.26:81/MavenCRM/uploads/Excel/",
  // profile_img : "http://43.229.227.26:81/MavenCRM/uploads/User_Doc/1_ImagePath.png",

  // upload_user_file: "http://43\.229\.227\.26:81/Maven-CRM/assets/images/",
  // upload_user_fileIcon: "http://43\.229\.227\.26:81/Maven-CRM/assets/images/file_icons/",
};
// https://demoprojects.itsabacus.net/Maven_development

// http://43.229.227.26:81/Maven-CRM



