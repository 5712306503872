import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [AppComponent, FullComponent, LoginComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(Approutes, {
      relativeLinkResolution: "legacy",
      preloadingStrategy: PreloadAllModules,
    }),
    SharedModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
