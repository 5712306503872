<section class="body-bg">
    <img class="wave" src="assets/images/wave.png" alt="">
    <div class="container">
        <div class="login-content m-auto">
            <form action="" class="animated fadeIn">
                <h1 class="title">Company LOGO</h1>
                <h2 class="title">Welcome</h2>
                <p>Create your account, it takes less than a minute.
                    <span class="mdi mdi-bell-outline mdi-18px text-warning"></span>
                </p>

                <div class="form-row">
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-account"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>First Name</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-account"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Last Name</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-email"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Business Email</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-home-modern"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Company Name</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-clipboard-account"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Company Registered Country</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi mdi-file-check"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Company Legal Form</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi mdi-home-map-marker"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Company Address</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi mdi-city"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Country</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi mdi-city"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>State</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi mdi-phone"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>Phone</span>
                            </label>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-checkbox-multiple-blank-circle-outline"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>MC#</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-checkbox-multiple-blank-circle-outline"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>DOT#</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="input-div one">
                            <div class="i">
                                <i class="mdi mdi-checkbox-multiple-blank-circle-outline"></i>
                            </div>
                            <label class="has-float-label">
                                <input class="form-control" type="text" placeholder="" />
                                <span>FF#</span>
                            </label>
                        </div>
                    </div>
                </div>


                <div class="d-flex justify-content-start align-items-center my-3">
                    <div class="round-checkbox">
                        <input type="checkbox" id="checkbox" checked />
                        <label for="checkbox"></label>
                    </div>
                    <span class="ml-3">I accept the <a href="javascript:void(0);" (click)="termsCondition.show()"> Terms
                            & Conditions </a></span>
                </div>


                <div class="d-flex align-items-center my-3">
                    <input type="submit" class="btn" value="Create an Account">
                    <input type="submit" class="btn btn-light ml-3" value="Go to Login" routerLink="/login">
                </div>
                <div class="w-100">
                    <p class="mb-3">Or you can join with</p>
                    <div class="social-media">
                        <a href="javascript:void(0);" class="social-icon"><span class="mdi mdi-facebook"></span></a>
                        <a href="javascript:void(0);" class="social-icon"><span class="mdi mdi-twitter"></span></a>
                        <a href="javascript:void(0);" class="social-icon"><span class="mdi mdi-google"></span></a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>



<!--Terms & Conditions modal-->
<div class="modal fade" bsModal #termsCondition="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content animated zoomIn">
            <div class="modal-header">
                <h4 id="dialog-static-name" class="modal-title pull-left">Terms & Conditions</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="termsCondition.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h4><strong>Terms Of Use</strong></h4>
                <p>This Web Site, as defined below, is maintained by PriceRite Freight Solutions LLC. ©2020. Content on
                    this site may not be used for commercial benefit without expressed written consent of PriceRite
                    Freight Solutions LLC.</p>
                <p>
                    PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEB SITE. THESE WEB SITE TERMS OF USE
                    (THE "TERMS OF USE") GOVERN YOUR ACCESS TO AND USE OF THE WEB SITE. IF YOU MUST BE 18 YEARS OF AGE
                    OR OLDER, OR THE AGE OF MAJORITY AS THAT IS DEFINED IN YOUR JURISDICTION, WHICHEVER IS OLDER, TO USE
                    THE WEB SITE IN ANY MANNER. THE WEB SITE IS AVAILABLE FOR YOUR USE ONLY ON THE CONDITION THAT YOU
                    AGREE TO THE TERMS OF USE SET FORTH BELOW. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE, DO NOT
                    ACCESS OR USE THE WEB SITE. BY ACCESSING OR USING THE WEB SITE, YOU AND THE ENTITY YOU ARE
                    REPRESENTING ("YOU" OR "YOUR") SIGNIFY YOUR AGREEMENT TO BE BOUND BY THE TERMS OF USE. YOUR ACCESS
                    AND USE OF THE WEB SITE REPRESENTS YOUR WARRANTY TO PRICERITE FREIGHT SOLUTIONSLLC. THAT YOU WILL
                    USE THE WEB SITE IN A MANNER CONSISTENT WITH ANY AND ALL APPLICABLE LAWS AND REGULATIONS.
                </p>
                <p>As used herein, all references to "Web Site" may include, individually, all and/or in any
                    combination, Priceritefreightsolutions; Invito.com; Invitosystems.com, as well as other web sites
                    operated by or on behalf of PriceRite Freight SolutionsLLC., and also includes mobile applications
                    including but not limited to INVITO customer mobile application and/or PriceRiteTrucks carrier
                    mobile application. The Web Site is maintained by PriceRite Freight SolutionsLLC. All references to
                    "PriceRite Freight" made within these Terms of Use shall be references to and include PriceRite
                    Freight SolutionsLLC., its affiliates and subsidiaries and each of their directors, officers,
                    employees, and authorized representative ("PriceRite Freight"). PriceRite Freight Solutionsis
                    headquartered at 650 Warrenville Road Lisle Illinois 60532. The main telephone number is (888)
                    980-9875. All material on this Web Site, including, but not limited to text, images, and
                    illustrations, is protected by copyrights and/or trademarks, registered and unregistered, which are
                    owned and controlled by PriceRite Freight Solutions or by other parties that have licensed their
                    material to PriceRite Freight. Material from any Web Site owned, operated, licensed, or controlled
                    by PriceRite Freight Solutions may not be copied, reproduced, republished, uploaded, posted,
                    transmitted, or distributed in any way. The materials on this Web Site may not be used for
                    commercial benefit without expressed written permission by PriceRite Freight. PriceRite Freight
                    Solutions authorizes You to view and access a single copy of the content available on or from the
                    Web Site solely for Your personal use. Modification of the materials or use of the materials for any
                    other purpose is a violation of the copyrights and other proprietary rights. Except as expressly
                    allowed by these Terms of Use and/or some other written agreement between You and PriceRite Freight,
                    the use of any such material on any other website or networked computer environment is prohibited.
                    This Web Site may contain other proprietary notices, trademark, and/or copyright information, the
                    terms of which must be observed and followed. Information may be changed or updated without notice.
                    PriceRite Freight Solutions may also make improvements and/or changes in the products and/or the
                    programs described in this information at any time without notice.</p>
                <p>You may not use the Web Site in order to transmit, post, distribute, store or destroy material,
                    including without limitation, content from the Web Site, (a) in violation of any applicable law or
                    regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other
                    intellectual property rights of PriceRite Freight Solutions or any other third party or violate the
                    privacy, publicity or other personal rights of others, or (c) that is defamatory, obscene,
                    threatening, abusive or hateful. PriceRite Freight Solutions will not release Your name or otherwise
                    publicize the fact that You submitted materials or other information to us unless: (z) PriceRite
                    Freight Solutions obtain Your permission to use Your name; or (y) PriceRite Freight Solutions first
                    notify You that the materials or other information You submit to a particular part of this Web Site
                    will be published or otherwise used with Your name on it; or (x) PriceRite Freight Solutions are
                    required to do so by law.</p>
                <p>If the Web Site is interactive, allowing you to upload and/or download content from the Web Site, you
                    will be issued a personal user id and password, which will allow you, in accordance with these Terms
                    of Use, access and use your account. We understand that the privacy and security of data that are
                    maintained on the Web Site are important, and therefore, we use commercially reasonable security
                    technology, features and policies to protect that privacy. However, please keep in mind that such
                    records are only secure if You protect Your password and change Your password frequently.</p>
                <p>When you upload and/or download information to or from the Web Site, such content uploads and
                    downloads will be communicated using commercially reasonable security procedures and processes.
                    However, PriceRite Freight Solutions cannot and will not guarantee that: (a) the content, during its
                    transmission, will be protected against loss, misuse or alteration by third parties; or (b) access
                    to Your account will be uninterrupted; or (c) the commercially reasonable security procedures and
                    processes cannot be breached.</p>
                <p>It is Your responsibility to ensure that no unauthorized person shall have access to Your user id and
                    password. It is Your sole responsibility to control access to and use of Your user account, and
                    promptly inform PriceRite Freight Solutions of any need to deactivate or change Your user id and/or
                    password. PriceRite Freight Solutions cannot and will not assume any responsibility or liability for
                    any content submitted to Your account or any content that is used or misused whether submitted,
                    used, or misused by You, Your designees, and/or other third parties. All Your acts and omissions and
                    those of unauthorized users who access the Web Site via Your user id and password shall be deemed to
                    be Your acts and omissions.</p>

                <p>You hereby grant PriceRite Freight, its employees, officers, directors and all other persons or
                    entities involved in the operation of PriceRite Freight, the right to access, transmit, receive,
                    monitor, retrieve, store, maintain, and use the content in your account for the purpose of providing
                    services, operating the Web Site, or in order to carry out the legal responsibilities of PriceRite
                    Freight. You understand, agree, consent and authorize Your content to be stored, entered, and
                    maintained on the Web Site in accordance with these Terms of Use and PriceRite Freight’s relevant
                    policies and procedures.</p>
                <p>Regarding Your user id and password, You expressly agree (i) to maintain the security of Your users
                    id, password, and other confidential information relating to Your account and/or the accounts which
                    you have been granted access; (ii) to notify PriceRite Freight Solutions as soon as reasonably
                    practicable of any real or suspected unauthorized use of the users account; (iii) to notify
                    PriceRite Freight Solutions when You no longer require the use of Your user id and password; and
                    (iv) to comply with instructions provided by PriceRite Freight Solutions for using, maintaining, or
                    correcting Your account.</p>
                <p>Information PriceRite Freight publishes on the World Wide Web may contain references or cross
                    references to products, programs, and services that are not announced or available in Your country.
                    Such references do not imply that PriceRite Freight Solutions intends to announce such products,
                    programs, or services in Your country. Consult Your local PriceRite Freight Solutions business
                    contact for information regarding the products, programs, and services that may be available to You.
                </p>
                <p>PriceRite Freight Solutions makes no representations whatsoever about any other web sites which You
                    may access through this Web Site. When You access a non-PriceRite Freight Solutions web site through
                    this Web Site, please understand that it is independent from PriceRite Freight, and that PriceRite
                    Freight Solutions has no control over the content on that web site. In addition, a link to a
                    non-PriceRite Freight Solutions Web site does not mean that PriceRite Freight Solutions endorses or
                    accepts any responsibility for the content, or the use, of such web site. It is up to You to take
                    precautions to ensure that whatever You select for Your use is free of such items as viruses, worms,
                    Trojan horses and other items of a destructive nature. Nothing on this Web Site constitutes an offer
                    to sell or solicitation to buy securities. No failure or delay by PriceRite Freight Solutions in
                    exercising any right hereunder will operate as a waiver thereof, nor will any partial exercise of
                    any right or power hereunder by PriceRite Freight Solutions preclude further exercise. No agency,
                    partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended nor
                    created by these Terms of Use.</p>
                <p>PriceRite Freight Solutions DOES NOT WARRANT THAT THE WEB SITE IS ERROR-FREE OR THAT THE WEB SITE
                    WILL BE PROVIDED. MATERIALS FOUND WITHIN THIS WEB SITE MAY CONTAIN TECHNICAL INACCURACIES OR OTHER
                    ERRORS. ALL MATERIALS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY
                    KIND EITHER EXPRESS OR IMPLIED. TO THE GREATEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
                    PriceRite Freight Solutions DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                    TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Some jurisdictions
                    do not allow the disclaimer of implied warranties, so these limitations may not apply; however, they
                    SHALL apply to the greatest extent permitted by law.</p>
                <p>PriceRite Freight Solutions WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
                    SPECIAL AND/OR CONSEQUENTIAL DAMAGES (SUCH DAMAGES INCLUDING WITHOUT LIMITATION LOST PROFITS, LOST
                    REVENUES, LOST DATA, AND/OR COSTS OF PROCURING SUBSTITUTE GOODS, SOFTWARE OR SERVICES) HOWEVER
                    ARISING, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU EXPRESSLY AGREE
                    PriceRite Freight Solutions SHALL HAVE NO LIABILITY TO YOU FOR ANY CLAIM BROUGHT AGAINST YOU BY A
                    THIRD PARTY. TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, IF PriceRite Freight Solutions IS
                    RESPONSIBLE FOR ANY DAMAGES, THE MAXIMUM AMOUNT YOU MAY RECOVER FOR ANY AND ALL CLAIMS RELATING TO
                    THIS YOUR USE OF THE WEB SITE SHALL NOT EXCEED TWO HUNDRED DOLLARS ($200). Some jurisdictions do not
                    allow the exclusion or limitation of certain types of damages, so these exclusions may not apply;
                    however, they shall apply to the greatest extent permitted by law.</p>
                <p>These Terms of Use shall be governed by, construed, and enforced in accordance with the laws of the
                    State of Illinois, as it is applied to agreements entered and to be performed entirely within such
                    State. Any action You, any third-party, or PriceRite Freight Solutions may bring to enforce these
                    Terms of Use or, in connection with, any matters related to this Web Site shall be brought only in
                    either the state or Federal Courts located in Dupage County, Illinois, and You expressly consent to
                    the jurisdiction of said courts. If any provision of these Terms of Use shall be unlawful, void, or
                    for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use
                    and shall not affect the validity and enforceability of any remaining provisions. Except to the
                    extent that You and PriceRite Freight Solutions have entered into a written agreement regarding the
                    provision of services (including but not limited to transportation services, logistics services,
                    warehouse services or any other services) by PriceRite Freight Solutions to You, or by You to
                    PriceRite Freight, and such written agreement expressly contemplates the use of this Web Site, these
                    Terms of Use are the entire agreement between the parties relating to the matters contained herein
                    and shall not be modified except in writing, signed by PriceRite Freight.</p>

                <h4><strong> Blog Terms of Use</strong></h4>
                <p><i>A PriceRite Freight Solutions Web Site, as defined above, may contain and/or support one or more
                        blogs related to transportation logistics issues. These additional BLOG Terms of Use pertain to
                        Your use of any blog found on any PriceRite Freight Solutions Web Site.</i></p>
                <p>Your access to, and use of, any PriceRite Freight Solutions blog is subject to the following Terms of
                    Use and all applicable laws and regulations. By accessing and using any PriceRite Freight Solutions
                    blog, you accept, without limitation or qualification, these BLOG Terms of Use, and agree to be
                    bound by them.</p>
                <p>PriceRite Freight Solutions encourages You to express Your opinion, but please do not post offensive
                    content. Offensive content includes but is not limited to racial or ethnic slurs, personal insults,
                    content of a sexually explicit nature, and/or obscenity. You may not disclose any type of
                    confidential information. Please be yourself but do so in a respectful manner, as PriceRite Freight
                    Solutions reserve the right and discretion to edit, pull or refrain from posting comments as
                    PriceRite Freight Solutions sees fit. All PriceRite Freight Solutions blogs are a platform for
                    journalism and commentary. They are not advertisement for specific services, including any type of
                    legal service and shall not be construed as legal advice. You are prohibited from using any
                    PriceRite Freight Solutions blog to advertise, perform or promote any commercial solicitation of
                    goods and/or services, other than those offered by PriceRite Freight.</p>
                <p>By posting on a PriceRite Freight Solutions blog, You grant PriceRite Freight Solutions a
                    non-exclusive, royalty-free license to use, publish, broadcast and reproduce Your comments without
                    limitation in any and all media, worldwide, in perpetuity, for any purpose whatsoever. This
                    includes, without limitation, the right to create derivative works of, and to use, the content
                    posted on any PriceRite Freight Solutions blog to develop, enhance, distribute, and market PriceRite
                    Freight’s services. You represent and warrant that all content You post is Your original work in
                    which You own the copyright, or have been granted permission to use and in no way violates or
                    infringes on the copyright, registered trademark or intellectual property rights of a third party.
                    If You see something interesting and relevant, link to it! Except where otherwise noted, Your use of
                    the content on a PriceRite Freight Solutions blog is licensed under a Creative Commons Attribution
                    3.0 License. This does not give You the right to use any of PriceRite Freight’s trade names,
                    trademarks, service marks, logos, domain names, and other distinctive brand features without our
                    consent, except as necessary to provide attribution.</p>
                <p>In consideration of Your use of any PriceRite Freight Solutions blog, You agree to provide complete
                    and accurate information about yourself as prompted by our registration form, and agree to update it
                    as appropriate. PriceRite Freight Solutions has the right to suspend or terminate Your right to post
                    blogs if PriceRite Freight Solutions believes that the information You have provided is inaccurate
                    or incomplete. Any personal data information PriceRite Freight Solutions collects from You is in
                    accordance with PriceRite Freight’s Privacy Policy. Any other material You transmit will be treated
                    as non-confidential and non-proprietary. YOU AGREE TO DEFEND, INDEMNIFY AND HOLD PRICERITE FREIGHT,
                    AND ITS SUBSIDIARIES, AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                    SUCCESSORS AND ASSIGNS, HARMLESS FROM ANY CLAIM OR DEMAND (INCLUDING REASONABLE ATTORNEY’S FEES AND
                    COSTS), MADE BY ANY THIRD PARTY ARISING OUT OF (I) ANY CONTENT POSTED BY YOU ON ANY PRICERITE
                    FREIGHT SOLUTIONSBLOG, (II) YOUR VIOLATION OF THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS, OR (III)
                    YOUR VIOLATION OF THESE TERMS OF USE. PriceRite Freight Solutions makes no warranties, expressed or
                    implied, or assumes any legal liability or responsibility for the accuracy, completeness, or
                    usefulness of any content posted on any PriceRite Freight Solutions blog. Any reference made within
                    any PriceRite Freight Solutions blog to any specific commercial products, process, or service by
                    trade name, trademark manufacturer, or otherwise, does not necessarily constitute or imply its
                    endorsement, recommendation, or favoring by PriceRite Freight. All views and opinions of the authors
                    expressed herein are the writer’s own and do not necessarily state or reflect those of PriceRite
                    Freight. YOU AGREE THAT PRICERITE FREIGHT, AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS,
                    EMPLOYEES, AGENTS, SUCCESSORS AND ASSIGNS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                    PROFITS (EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR
                    RESULTING FROM THE USE OF ANY PRICERITE FREIGHT SOLUTIONSBLOG. SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OF CERTAIN WARRANTIES OR LIMITATION OF DAMAGES. IN SUCH JURISDICTIONS, THESE EXCLUSIONS
                    AND LIMITATIONS MAY NOT APPLY TO YOU. These Terms of Use of any PriceRite Freight Solutions Blog
                    shall be governed by the laws of the State of Illinois. You consent that the courts located in and
                    serving the State of Illinois shall have exclusive jurisdiction and venue over any dispute arising
                    out of or relating to these BLOG Terms of Use.</p>
            </div>
        </div>
    </div>
</div>