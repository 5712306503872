import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/manage-shipment',
    //     title: 'Manage Shipment',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/transportation-list',
    //     title: 'Transportation System',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/warehouse-list',
    //     title: 'Warehouse List',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/user-permission',
    //     title: 'User Management',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/billing-invoicing',
    //     title: 'Billing & Invoicing',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/delivery-proof',
    //     title: 'Delivery Proof',
    //     icon: 'mdi mdi-checkbox-marked-circle',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
];
