<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="
    {
        'defaultdark': color == 'defaultdark',
        'mini-sidebar': showMinisidebar
    }
">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <header class="topbar">
        <nav class="d-flex top-navbar navbar-expand-md navbar-light px-1">
            <!-- ============================================================== -->
            <!-- Logo -->
            <!-- ============================================================== -->

            <div class="navbar-header">

                <a class="navbar-brand" href="javascript:void(0);">
                    <!--End Logo icon -->
                    <!-- Logo text -->
                    <span>
                        <!-- dark Logo text -->
                        <img src="assets/images/logo.png" *ngIf="!showMinisidebar" alt="Invito"
                            class="dark-logo img-fluid" width="120" />
                        <!-- Light Logo text -->
                        <img src="assets/images/logo-small.png" *ngIf="showMinisidebar" width="30"
                            class="light-logo d-md-block d-block" alt="Invito" />
                    </span>
                </a>
            </div>

            <div class="navbar-collapse">
                <app-navigation class="w-100 d-flex align-items-center justify-content-between"
                    (toggleSidebar)="toggleSidebar()"></app-navigation>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->

        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->

    <aside class="left-sidebar">
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            <app-sidebar></app-sidebar>
        </div>
    </aside>

    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->

    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid h-100">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->

        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->

</div>