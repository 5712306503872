import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/authentication/auth.service';
import { UserService } from '../services/userService/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginBox: boolean = true;
  forgotPasswordBox: boolean = false;
  registerForm: FormGroup;
  submitted = false;
  forgetPassForm: FormGroup;
  submittedForm = false;
  userid: any;
  userInformation: any;

  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private UserService: UserService,
    private spinner: NgxSpinnerService,
    private _authService: AuthService,
    private _router: Router
  ) {
  }

  ngOnInit(): void {

    if (this._authService.loggedIn()) {
      this._router.navigate(['/profile']);
    }

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.forgetPassForm = this.formBuilder.group({
      email_id: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.registerForm.controls; }
  get p() { return this.forgetPassForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let data = {
      Email: this.registerForm.value.email,
      Password: this.registerForm.value.password
    }
    if (this.registerForm.valid) {
      this.spinner.show();
      this.AuthService.login(data).subscribe(
        res => {
          this.spinner.hide();
          if (res['result'] == 'success') {
            localStorage.clear();
            localStorage.setItem('token', res['AccessToken']);
            let userid = res['Data']['UserID'];
            let encryptuserid = this.UserService.encryptData(userid);
            localStorage.setItem('userId', encryptuserid);
            let roleId = res['Data']['RoleId'];
            let encryptroleid = this.UserService.encryptData(roleId);
            localStorage.setItem('roleId', encryptroleid);
            let permission = res['ModulePermission'];
            let comppermission = res['OperationPermission'];
            let encryptper = this.UserService.encryptData(comppermission);
            localStorage.setItem("Permission", JSON.stringify(encryptper));
            let encryptid = this.UserService.encryptData(permission);
            localStorage.setItem("ModulePermission", JSON.stringify(encryptid));
            localStorage.setItem("Account", res['Data']['IsAccountManager']);
            localStorage.setItem("Finance", res['Data']['IsFinanceManager']);
            localStorage.setItem("General", res['Data']['IsGeneralManager']);
            localStorage.setItem("Sales", res['Data']['IsSalesManager']);
            localStorage.setItem("Service", res['Data']['IsServiceManager']);
            this.router.navigate(['/profile']);
          } else if (res['result'] == 'Failed') {
            this.toastrService.error(res['Msg'])
          } else {
            this.toastrService.error(res['Message'])
          }
        })
    }
  }

  submitForgetPassForm() {
    this.submittedForm = true
    // stop here if form is invalid
    if (this.forgetPassForm.invalid) {
      return;
    }
    let data = {
      "Email": this.forgetPassForm.value.email_id
    }
    this.spinner.show();
    this.UserService.ForgotPassword(data).subscribe(res => {
      this.spinner.hide();
      if (res['result'] == 'success') {
        this.toastrService.success('Please check your email account for password')
      } else if (res['result'] == 'Failed') {
        this.toastrService.error(res['Msg'])
      } else {
        this.toastrService.error(res['Message'])
      }
    })
  }

  ForgotPassword() {
    this.forgotPasswordBox = true;
    this.loginBox = false;
  }

  login() {
    this.forgotPasswordBox = false;
    this.loginBox = true;
  }

}
