import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./login/login.component";

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [

      { path: "", redirectTo: "/login", pathMatch: "full" },

      {
        path: "profile",
        loadChildren: () =>
          import("./maven/profile/profile.module").then((m) => m.ProfileModule),
      },

      {
        path: "company",
        loadChildren: () =>
          import("./maven/company/company-list/company.module").then(
            (m) => m.CompanyModule
          ),
      },

      {
        path: "user",
        loadChildren: () =>
          import("./maven/user/user.module").then((m) => m.UserModule),
      },

      {
        path: "inquiry",
        loadChildren: () =>
          import("./maven/inquiry/inquiry.module").then((m) => m.InquiryModule),
      },

      {
        path: "Task",
        loadChildren: () =>
          import("./maven/task/task.module").then((m) => m.TaskModule),
      },

      {
        path: "contacts",
        loadChildren: () => import('./maven/contacts/contacts.module').then(m => m.ContactsModule)
      },

      {
        path: "productmanagment",
        loadChildren: () => import('./maven/productmanagment/productlist/product.module').then(m => m.ProductModule)
      },

      {
        path: "project",
        loadChildren: () => import('./maven/project/projectlist/project.module').then(m => m.ProjectModule)
      },

      {
        path: "orderReview",
        loadChildren: () => import('./maven/productreviewnew/productviewlist/orderreview.module').then(m => m.OrderreviewModule)
      },

      {
        path: "report",
        loadChildren: () => import('./maven/report/report.module').then(m => m.ReportModule)
      },

      {
        path: "salestarget",
        loadChildren: () => import('./maven/salestarget/sales-target.module').then(m => m.SalesTargetModule)
      },

      {
        path: "report-dashboard",
        loadChildren: () => import('./maven/report-dashboard/report-dashboard.module').then(m => m.ReportDashboardModule)
      },

      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "/booking",
            pathMatch: "full",
          },
        ]
      }
    ]
  },

  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "login",
    },
  },

  {
    path: "**",
    component: LoginComponent,
    data: {
      title: "Login",
    },
  }

];
