import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TwoDigitDecimaNumberDirective } from '../maven/inquiry/detailinquiry-editproduct/two-digit-decima-number.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TreeviewModule } from 'ngx-treeview';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LightboxModule } from 'ngx-lightbox';
import { PhoneMaskDirective } from '../phone-mask.directive';
import { MultiselectSearchFixDirective } from '../search-bug.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RecurrenceEditorAllModule, ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NiceSelectModule } from "ng-nice-select";
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './header-navigation/navigation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RolePermissionService } from '../appService/role-permission.service';
import { TokenInterceptorService } from '../services/token-interceptor.service';
import { AuthService } from '../services/authentication/auth.service';
import { AuthGuard } from '../guards/auth.guard';
import { UserService } from '../services/userService/user.service';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RegistrationComponent } from '../registration/registration.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SpinnerComponent } from './spinner.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    TwoDigitDecimaNumberDirective,
    PhoneMaskDirective,
    MultiselectSearchFixDirective,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RegistrationComponent,
    ChangePasswordComponent,
    TopNavComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxDaterangepickerMd.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    NgSelectModule,
    ImageCropperModule,
    AngularMultiSelectModule,
    MatFormFieldModule,
    TreeviewModule.forRoot(),
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    LightboxModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NgxDropzoneModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    ChartsModule,
    DataTablesModule,
    NiceSelectModule,
    ModalModule.forRoot(),
    AngularEditorModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ToastrModule.forRoot({
      timeOut: 2500,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    })
  ],
  providers: [
    RolePermissionService, AuthService, AuthGuard, UserService, DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  exports: [
    NgxSpinnerModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    ModalModule,
    NgbModule,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    SpinnerComponent,
    AngularMultiSelectModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    FormsModule,
    BsDatepickerModule,
    TreeviewModule,
    NgxDaterangepickerMd,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatFormFieldModule,
    AngularEditorModule,
    NgSelectModule
  ]
})

export class SharedModule { }
