import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  //Encrypt Data(n)
  encryptData(data) {
    try {
      if (data) {
        return CryptoJS.AES.encrypt(
          JSON.stringify(data),
          "1234509876encryptdecrypt1234567890"
        ).toString();
      }
    } catch (e) {}
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        "1234509876encryptdecrypt1234567890"
      );
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }
    } catch (e) {}
  }

  GetDesignation(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetDesignation`,
      data
    );
  }
  GetBusinessVertical(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetBusinessVertical`,
      data
    );
  }
  GetCountries(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCountries`,
      data
    );
  }
  GetStatesByCountryId(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetStatesByCountryId`,
      data
    );
  }
  GetCitiesByStateId(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCitiesByStateId`,
      data
    );
  }
  GetUserDetail(data) {
    return this.http.post(`${environment.api_url}/UserAPI/GetUserDetail`, data);
  }
  UserLogout(data) {
    return this.http.post(`${environment.api_url}/AccountAPI/UserLogout`, data);
  }
  ForgotPassword(data) {
    return this.http.post(
      `${environment.api_url}/AccountAPI/ForgotPassword`,
      data
    );
  }
  AddUpdateUser(data) {
    return this.http.post(`${environment.api_url}/UserAPI/AddUpdateUser`, data);
  }
  GetEmployeeDetail(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/GetEmployeeDetail`,
      data
    );
  }
  GetUserListFilter(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/GetUserListFilter`,
      data
    );
  }
  GetEmployeeName(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/GetEmployeeName`,
      data
    );
  }
  GetEditUserDetail(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/GetEditUserDetail`,
      data
    );
  }
  GetEditCompanyDetail(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/EditCompanyDetail`,
      data
    );
  }
  getcompanytype(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCompanyType`,
      data
    );
  }
  GetCompanyProfile(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCompanyProfile`,
      data
    );
  }
  GetBusinessStream(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetBusinessStream`,
      data
    );
  }
  GetAccountManager(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetAccountManager`,
      data
    );
  }
  GetCategory(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCategory`,
      data
    );
  }
  GetPaymentHistory(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetPaymentHistory`,
      data
    );
  }
  GetFinancialStatus(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetFinancialStatus`,
      data
    );
  }
  GetSupplierDeliveryHistory(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetSupplierDeliveryHistory`,
      data
    );
  }
  ImportUserDoc(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/ImportUserDocument`,
      data
    );
  }
  DeleteUserAttachment(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/DeleteUserAttachment`,
      data
    );
  }
  makeActiveInactived(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/UpdateCompanyStatus`,
      data
    );
  }
  CompanySubmit(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/AddUpdateCompany`,
      data
    );
  }
  CompanyListFilter(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/CompanyListFilter`,
      data
    );
  }
  GetPermissionList(data) {
    return this.http.post(
      `${environment.api_url}/PermissionAPI/GetPermissionList`,
      data
    );
  }
  InsertPermission(data) {
    return this.http.post(
      `${environment.api_url}/PermissionAPI/InsertPermission`,
      data
    );
  }
  deleteuser(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/UpdateUserStatus`,
      data
    );
  }
  makeActiveInactive(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/UpdateUserStatus`,
      data
    );
  }
  removecompanydocument(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/RemoveCompanyDocument`,
      data
    );
  }
  UploadCompanyDocument(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/UploadCompanyDocument`,
      data
    );
  }
  editcompanydetail(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/EditCompanyDetail`,
      data
    );
  }
  GetAllCompanyName(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/GetAllCompanyName`,
      data
    );
  }
  GetReligion(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/GetReligion`,
      data
    );
  }
  GetLanguage(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/GetLanguage`,
      data
    );
  }
  GetProReservation(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/GetProReservation`,
      data
    );
  }
  AddUpdateContact(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/AddUpdateContact`,
      data
    );
  }
  GetUserOwnDetail(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetUserOwnDetail`,
      data
    );
  }

  ImportUserDocument(data) {
    let formData = new FormData();
    formData.append("upload", data.Images);
    return this.http.post(
      `${environment.api_url}/UserAPI/ImportUserDocument?UserId=${data.UserId}`,
      formData
    );
  }

  //image//
  UploadUserPofile(Data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/UploadUserPofile`,
      Data
    );
  }

  removeProfilePic(Data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/RemoveUserPofile`,
      Data
    );
  }

  private TabSource = new BehaviorSubject("Main");
  private TabSourceprofileImage = new BehaviorSubject("");

  changeProfileImages(profileImage: string) {
    this.TabSource.next(profileImage);
    this.TabSourceprofileImage.next(profileImage);
  }

  addPost(frmData) {
    let id = frmData.get("UserId");
    return this.http.post(
      `${environment.api_url}/UserAPI/ImportUserDocument`,
      frmData
    );
  }
  ExportUserList(data) {
    return this.http.get(`${environment.api_url}/UserAPI/` + "" + data);
  }
  Importcompany(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/ImportCompanyList`,
      data
    );
  }
  SetCompanyPrefix(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/SetCompanyPrefix`,
      data
    );
  }
  DeleteCompany(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/DeleteCompany`,
      data
    );
  }
  RestoreCompany(data) {
    return this.http.post(
      `${environment.api_url}/CompanyAPI/RestoreCompany`,
      data
    );
  }
  ContactListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/ContactListFilter`,
      data
    );
  }
  ImportContactList(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/ImportContactList`,
      data
    );
  }
  GetEditContactDetail(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/GetEditContactDetail`,
      data
    );
  }
  UpdateContactStatus(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/UpdateContactStatus`,
      data
    );
  }
  RestoreContact(data) {
    return this.http.post(
      `${environment.api_url}/ContactAPI/RestoreContact`,
      data
    );
  }
  GetAllUser(data) {
    return this.http.post(`${environment.api_url}/ProjectAPI/GetAllUser`, data);
  }
  GetTerritory(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetTerritory`,
      data
    );
  }
  GetProjectSource(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetProjectSource`,
      data
    );
  }
  AddUpdate(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/AddUpdateProject`,
      data
    );
  }
  GetProjectStatus(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetProjectStatus`,
      data
    );
  }
  EditProject(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/EditProjectById`,
      data
    );
  }
  ProjectListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/ProjectListFilter`,
      data
    );
  }
  RestoreProject(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/RestoreProject`,
      data
    );
  }
  UpdateProjectStatus(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/UpdateProjectStatus`,
      data
    );
  }
  ExportProjectList(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/ExportProjectList`,
      data
    );
  }
  Restore(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/RestoreProject`,
      data
    );
  }
  GetAllProject(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetAllProject`,
      data
    );
  }
  ImportProjectList(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/ImportProjectList`,
      data
    );
  }
  GetProjectActivityHistory(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetProjectActivityHistory`,
      data
    );
  }
  GetInquiryListByProjectId(data) {
    return this.http.post(
      `${environment.api_url}/ProjectAPI/GetInquiryListByProjectId`,
      data
    );
  }

  ChangePassword(data) {
    return this.http.post(
      `${environment.api_url}/UserAPI/ChangePassword`,
      data
    );
  }

  AddUpdateProject(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/AddUpdateProject`,
      data
    );
  }
  UpdateProductStatus(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/UpdateProductStatus`,
      data
    );
  }
  GetProductDetails(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/GetProductDetails`,
      data
    );
  }
  ProductListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/ProductListFilter`,
      data
    );
  }
  GetItemCodeByBV(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/GetItemCodeByBV`,
      data
    );
  }
  ImportProductList(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/ImportProductList`,
      data
    );
  }
  GetDetailsByBusinessVertical(data) {
    return this.http.post(
      `${environment.api_url}/ProductAPI/GetDetailsByBusinessVertical`,
      data
    );
  }
  GetInquiryProductByInquiryId(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryProductByInquiryId`,
      data
    );
  }
  GetBrandAndItemCodeDetails(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetBrandAndItemCodeDetails`,
      data
    );
  }
  UpdateInquiryCurrencyRate(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/UpdateInquiryCurrencyRate`,
      data
    );
  }
  AddupdateInquiryProduct(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/AddupdateInquiryProduct`,
      data
    );
  }
  GetProductListByBVId(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetProductListByBVId`,
      data
    );
  }
  GetUnitType(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetUnitType`,
      data
    );
  }
  GetIncoTermList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetIncoTermList`,
      data
    );
  }
  GetDeliveryPeriodList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetDeliveryPeriodList`,
      data
    );
  }
  GetSpecifiedList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetSpecifiedList`,
      data
    );
  }

  // Ashutosh
  AddUpdateInquiry(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/AddUpdateInquiry`,
      data
    );
  }
  GetStageList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetStageList`,
      data
    );
  }
  GetInquiryProbabilityList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryProbabilityList`,
      data
    );
  }
  GetReasonList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetReasonList`,
      data
    );
  }
  GetDeliveryTermList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetDeliveryTermList`,
      data
    );
  }
  GetApplicationList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetApplicationList`,
      data
    );
  }
  AddProjectInquiry(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/AddProjectInquiry`,
      data
    );
  }
  GetInquiryDetailById(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryDetailById`,
      data
    );
  }
  UpdateInquiryStatus(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/UpdateInquiryStatus`,
      data
    );
  }
  InquiryListFilter(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/InquiryListFilter`,
      data
    );
  }
  GetAllCustomer(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetAllCustomer`,
      data
    );
  }
  GetBusinessCategory(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetBusinessCategory`,
      data
    );
  }
  GetInquiryNumber(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryNumber`,
      data
    );
  }
  GetRevisionList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetRevisionList`,
      data
    );
  }

  UploadInquiryDocument(frmData) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/UploadInquiryDocument`,
      frmData
    );
  }
  InquiryActivityHistory(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/InquiryActivityHistory`,
      data
    );
  }
  GetTaskListByInquiryId(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetTaskListByInquiryId`,
      data
    );
  }
  GetContactIdByInquiryId(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetContactIdByInquiryId`,
      data
    );
  }
  ImportInquiryList(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/ImportInquiryList`,
      data
    );
  }
  ImportInquiryDocument(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/ImportInquiryDocument`,
      data
    );
  }
  DeleteInquiryAttachment(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/DeleteInquiryAttachment`,
      data
    );
  }
  InquiryDuplicate(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/InquiryDuplicate`,
      data
    );
  }
  InquiryReplica(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/InquiryReplica`,
      data
    );
  }
  GetInquiryNumberByProjectId(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryNumberByProjectId`,
      data
    );
  }
  GetInquiryProductCompetitor(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/GetInquiryProductCompetitor`,
      data
    );
  }

  GetCurrencyList(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetCurrencyList`,
      data
    );
  }

  TaskActivityHistory(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/TaskActivityHistory`,
      data
    );
  }
  TaskTitle(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/GetTaskTitleList`,
      data
    );
  }
  AddUpdateTask(data) {
    return this.http.post(`${environment.api_url}/TaskAPI/AddUpdateTask`, data);
  }
  TaskStatus(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/GetTaskStatusList`,
      data
    );
  }
  TaskPriority(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/GetTaskPriorityList`,
      data
    );
  }
  GetInqueryListByProjectId(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/GetInqueryListByProjectId`,
      data
    );
  }
  GetTaskDetailById(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/GetTaskDetailById`,
      data
    );
  }
  TaskListFilter(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/TaskListFilter`,
      data
    );
  }
  UpdateTaskStatus(data) {
    return this.http.post(
      `${environment.api_url}/TaskAPI/UpdateTaskStatus`,
      data
    );
  }

  AddUpdateOrderInformation(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderInformation`,
      data
    );
  }
  AddUpdateOrderItemDescription(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderItemDescription`,
      data
    );
  }
  AddUpdateOrderCommercialInformation(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderCommercialInformation`,
      data
    );
  }
  AddUpdateOrderPbAtAb(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderPbAtAb`,
      data
    );
  }
  AddUpdateOrderProjectRequirement(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderProjectRequirement`,
      data
    );
  }
  AddUpdateOrderPaymentTermInfo(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/AddUpdateOrderPaymentTermInfo`,
      data
    );
  }
  GetInquiryOrderInfoById(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetInquiryOrderInfoById`,
      data
    );
  }
  GetOrderReviewTOValues(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetOrderReviewTOValues`,
      data
    );
  }
  GetOrderReviewDDValues(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetOrderReviewDDValues`,
      data
    );
  }
  GetOrderReviewDetailById(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetOrderReviewDetailById`,
      data
    );
  }
  OrderReviewListFilter(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/OrderReviewListFilter`,
      data
    );
  }
  OrderActivityHistory(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/OrderActivityHistory`,
      data
    );
  }
  UploadOrderDocument(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/UploadOrderDocument`,
      data
    );
  }
  RemoveOrderDocument(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/RemoveOrderDocument`,
      data
    );
  }
  RemoveOrderPDF(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/RemoveOrderPDF`,
      data
    );
  }
  UpdateOrderStatus(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/UpdateOrderStatus`,
      data
    );
  }
  ApproveOrderOverView(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/ApproveOrderOverView`,
      data
    );
  }
  RejectOrder(data) {
    return this.http.post(`${environment.api_url}/OrderAPI/RejectOrder`, data);
  }
  ForwardOrder(data) {
    return this.http.post(`${environment.api_url}/OrderAPI/ForwardOrder`, data);
  }
  GetOrderReviewPDFById(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetOrderReviewPDFById`,
      data
    );
  }
  UpdateInquiryProductStatus(data) {
    return this.http.post(
      `${environment.api_url}/InquiryAPI/UpdateInquiryProductStatus`,
      data
    );
  }
  GetAllCompanyForOrder(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetAllCompanyForOrder`,
      data
    );
  }
  GetClientContactByInquiryId(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetClientContactByInquiryId`,
      data
    );
  }
  GetOptionValue(data) {
    return this.http.post(
      `${environment.api_url}/UtilityAPI/GetOptionValue`,
      data
    );
  }
  GetStageListForOrder(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/GetStageListForOrder`,
      data
    );
  }
  UpdateOrderCurrencyRate(data) {
    return this.http.post(
      `${environment.api_url}/OrderAPI/UpdateOrderCurrencyRate`,
      data
    );
  }

  //Report API
  ExpectedOrderListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/ExpectedOrderListFilter`,
      data
    );
  }
  PipeLineByStageListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/PipeLineByStageListFilter`,
      data
    );
  }

  SalesByProductListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/SalesByProductListFilter`,
      data
    );
  }

  PerformanceBySalesEngList(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/PerformanceBySalesEngList`,
      data
    );
  }

  SalesEngDetailsWithQuotEngList(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/SalesEngDetailsWithQuotEngList`,
      data
    );
  }

  OrdersByBVList(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/OrdersByBVList`,
      data
    );
  }

  LostInquiriesListFilter(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/LostInquiriesListFilter`,
      data
    );
  }

  SalesTargetAndAchieveReport(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/SalesTargetAndAchieveReport`,
      data
    );
  }

  AddupdateSalesTarget(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/AddupdateSalesTarget`,
      data
    );
  }

  GetFinancialYear(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetFinancialYear`,
      data
    );
  }

  GetSalesTargetEditByYear(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetSalesTargetEditByYear`,
      data
    );
  }

  GetSalesTargetDetailsByYear(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetSalesTargetDetailsByYear`,
      data
    );
  }

  SalesTargetList(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/SalesTargetList`,
      data
    );
  }

  GetYearlyTargetVsAchievement(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetYearlyTargetVsAchievement`,
      data
    );
  }

  GetMonthlyTargetVsAchievement(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetMonthlyTargetVsAchievement`,
      data
    );
  }

  GetReportChartData(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetReportChartData`,
      data
    );
  }
  GetAllUserByStatus(data) {
    return this.http.post(
      `${environment.api_url}/ReportAPI/GetAllUserByStatus`,
      data
    );
  }
}
