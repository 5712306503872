import { Injectable } from "@angular/core"; // we may have to add Injector if use of auth service directly not works
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { AuthService } from "./authentication/auth.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let tokenizedReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`,
      },
    });

    return next.handle(tokenizedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.error.Message ==
          "Authorization has been denied for this request."
        ) {
          localStorage.removeItem("token");
          this.toastrService.error("Session expired! Please login again.");
          this.spinner.hide();
          this.router.navigate(["/login"]);
        }
        return throwError(error.error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}
